import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AsianEggBankLandingPage = () => (
  <PhysicianLandingPage
    physician="Asian Egg Bank"
    institution="Asian Egg Bank"
    referralCode="ASIANEGGBANK"
    physicianURL="https://www.asianeggbank.com/"
  />
)

export default AsianEggBankLandingPage
